import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import {
    AccordionPanel,
} from 'reaxl';
import { useFeatures } from 'reaxl-features';
import {
    FilterCheckboxes,
} from 'reaxl-filters';

import getFilterTitle from '@/utilities/getFilterTitle';

import {
    srpFiltersDuck,
} from '@/ducks/srp';

import FilterPlaceholder from './FilterPlaceholder';

/**
*  HomeServicesFilter presents the user with the ability to filter using home services attributes
*/
function HomeServicesFilter({
    handleShowExpansion = () => { },
    onOptionChange = () => { },
    onOptionsClear = () => { },
}) {
    const {
        srp_ways_to_purchase: [enableWaysToPurchase],
    } = useFeatures([
        'srp_ways_to_purchase',
    ]);

    const homeServicesValues = useSelector(srpFiltersDuck.selectors.getHomeServicesValues);
    const homeServicesOptions = useSelector(srpFiltersDuck.selectors.getHomeServicesOptions);

    const homeServicesNewGroupedOptions = {
        ...homeServicesOptions,
        options: [...(homeServicesOptions?.options?.filter((option) => option.value !== 'BUY_ONLINE') || [])],
    };

    const homeServicesExtractedValues = homeServicesValues.filter((item) => item !== 'BUY_ONLINE');

    const filterOptions = homeServicesNewGroupedOptions;

    const filtersErrors = useSelector(srpFiltersDuck.selectors.getFiltersErrors);
    const filterValues = {
        homeServices: homeServicesExtractedValues,
    };

    const title = getFilterTitle('At Home Services', homeServicesExtractedValues);

    const filterName = 'homeServices';
    const isExpanded = useSelector((state) => srpFiltersDuck.selectors.isExpansionActive(state, filterName));
    const showFilter = isExpanded && Object.keys(filterValues).length !== 0;

    // expand the panel if active options inside of it
    useEffect(() => {
        if (homeServicesExtractedValues.length) {
            handleShowExpansion(filterName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [homeServicesExtractedValues.length]);

    return (!enableWaysToPurchase
        && (
            <AccordionPanel
                contentPadding={5}
                data-cmp="HomeServicesFilter"
                eventKey={filterName}
                title={title}
            >
                {!showFilter && <FilterPlaceholder uniqueKey={filterName} />}
                {showFilter && (
                    <FilterCheckboxes
                        key="key"
                        filter={filterOptions}
                        onOptionChange={onOptionChange}
                        onOptionsClear={onOptionsClear}
                        values={filterValues}
                        errors={filtersErrors}
                        withAccordion={false}
                    />
                )}
            </AccordionPanel>
        )
    );
}

export default HomeServicesFilter;
