import { fetchJSON } from '@bonnet/next/fetch';

export default ({ modelCode, listingType }) => {
    const statsMileage = fetchJSON('rest/lsc/crawl/stats/mileage?', {
        headers: {
            'Content-Type': 'application/json',
        },
        circuitBreaker: {
            id: 'statsMileage',
            timeout: 5000,
            resetTimeout: 300000,
            fallback: {
                error: 'Circuit Breaker for rest/lsc/crawl/stats/mileage is open',
            },
        },
        query: {
            modelCode,
            listingType,
        },
    });

    return statsMileage || {};
};
