const getSrpPageTypes = (query) => {
    const { makeCode, modelCode, trimCode, year, city, extColorSimple, vehicleStyleCode, sellerType, price, fuelTypeGroup, driveGroup, featureCode } = query;

    const hasSingleSelection = (selection) => (Array.isArray(selection) ? selection.length === 1 : !!selection);

    const hasSingleMake = hasSingleSelection(makeCode);
    const hasSingleModel = hasSingleSelection(modelCode);
    const hasSingleTrim = hasSingleSelection(trimCode);
    const hasSingleCity = hasSingleSelection(city);
    const hasSingleColor = hasSingleSelection(extColorSimple);
    const hasSingleBodyStyle = hasSingleSelection(vehicleStyleCode);
    const hasSingleSellerType = hasSingleSelection(sellerType);
    const hasSinglePrice = hasSingleSelection(price);
    const hasSingleFuelTypeGroup = hasSingleSelection(fuelTypeGroup);
    const hasSingleDriveGroup = hasSingleSelection(driveGroup);
    const hasFeatureCode = hasSingleSelection(featureCode);

    const isMakeUrl = !!(hasSingleMake && !hasSingleModel && !hasSingleTrim && !year); // make
    const isMMUrl = !!(hasSingleMake && hasSingleModel && !hasSingleTrim && !year); // make/model
    const isMMTUrl = !!(hasSingleMake && hasSingleModel && hasSingleTrim && !year); // make/model/trim
    const isYMMUrl = !!(hasSingleMake && hasSingleModel && !hasSingleTrim && year); // year/make/model
    const isYMMTUrl = !!(hasSingleMake && hasSingleModel && hasSingleTrim && year); // year/make/model/trim
    const isMMCityUrl = !!(hasSingleMake && hasSingleModel && hasSingleCity); // make/model/city
    const isCMMUrl = !!(hasSingleMake && hasSingleModel && hasSingleColor); // color/make/model
    const isBMMUrl = !!(hasSingleMake && hasSingleModel && hasSingleBodyStyle); // bodystyle/make/model
    const isOMMUrl = !!(hasSingleMake && hasSingleModel && hasSingleSellerType); // by-owner/make/model
    const isPMMUrl = !!(hasSingleMake && hasSingleModel && hasSinglePrice); // cars-under/over-$price/make/model
    const isFTGMMUrl = !!(hasSingleMake && hasSingleModel && hasSingleFuelTypeGroup); // fuel-type-group/make/model
    const isDTGMMUrl = !!(hasSingleMake && hasSingleModel && hasSingleDriveGroup); // drive-type-group/make/model
    const isFMMUrl = !!(hasSingleMake && hasSingleModel && hasFeatureCode); // feature/make/model

    return { isMakeUrl, isMMUrl, isMMTUrl, isYMMUrl, isYMMTUrl, isMMCityUrl, isCMMUrl, isBMMUrl, isOMMUrl, isPMMUrl, isFTGMMUrl, isDTGMMUrl, isFMMUrl };
};

export default getSrpPageTypes;
