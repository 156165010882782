export const alphaLeaderBoardContainerOnPositionChange = ({ actions, currentPosition, previousPosition }) => {
    if ((currentPosition === 'inside' || currentPosition === 'above') && previousPosition === undefined) {
        actions.hideFixedAd();
    }
    if (currentPosition === 'inside' && previousPosition === 'below') {
        actions.hideFixedAd();
    }
    if (currentPosition === 'below' && previousPosition === 'inside') {
        actions.showFixedAd();
        actions.setRefreshCount();
    }
};

export const fixedAdWayPointContainerOnPositionChange = (handlerProps) => {
    const { actions } = handlerProps;

    if (handlerProps.currentPosition === 'above' && handlerProps.previousPosition === undefined) {
        actions.showFixedAd();
    } else if (handlerProps.currentPosition === 'inside' && handlerProps.previousPosition === 'above') {
        actions.showFixedAd();
        setTimeout(() => {
            actions.refreshFixedAd('top');
        }, 1);
    }
};

export const showFixedAd = (handlerProps) => {
    handlerProps.actions.showFixedAd();
};

export const hideFixedAd = (handlerProps) => {
    handlerProps.actions.hideFixedAd();
};
