import { createSelector, DuckSelector, objectDuckCreator } from '@atc/modular-redux';

import { srpDuckPreset } from '@/ducks/presets';

import StatsMileageFetcher from '@/fetchers/srp/StatsMileageFetcher';

function calculateYearStats(yearStats = []) {
    let validYears = [];
    if (Array.isArray(yearStats)) {
        validYears = yearStats
            ?.reduce((acc, { name }) => {
                const mappedName = parseInt(name, 10);
                if (!Number.isNaN(mappedName)) acc.push(mappedName);
                return acc;
            }, []);
    }

    // If no valid years, return default values.
    if (validYears.length === 0) {
        return {
            minYear: null,
            difference: 'N/A',
        };
    }

    const currentYear = Number(new Date().getFullYear());

    return {
        minYear: Math.min(...validYears),
        difference: (currentYear - (validYears.reduce((sum, year) => sum + year, 0) / validYears.length)),
        maxDifference: currentYear - Math.min(...validYears),
    };
}

const getPercent = (countAll, count) => ((count / countAll * 100) || 0).toFixed(1);

const isTargetName = (name) => Number(name) > 0 && Number(name) < 250000;

const getHighestMileage = (facetStats) => facetStats
    .filter((item) => (isTargetName(item.name)) && item.count > 0)
    .reduce((highest, item) => (!highest || Number(item.name) > Number(highest.name) ? item : highest), { name: null });

const getNumberLocale = (value) => Number(value).toLocaleString('en-US', { maximumFractionDigits: 0 });

const getYearOfMaxMileage = (maxMileage, facetSearchFieldStats) => facetSearchFieldStats?.year?.reduce((acc, year) => (year.max === maxMileage ? year.name : acc), '');

const getMaxMileageByYear = (year, facetSearchFieldStats) => facetSearchFieldStats?.year?.reduce((acc, item) => (item.name === year ? item.max : acc), '');

const getOverMileageCount = (name, facetStats = []) => {
    const threshold = parseInt(name, 10);
    return facetStats.reduce((acc, stat) => (parseInt(stat.name, 10) >= threshold ? acc + stat.count : acc), 0);
};

const srpSEOMileageDuck = objectDuckCreator({
    ...srpDuckPreset,
    store: 'faq',
    initialState: {
        mileageData: {
            minYear: 0,
            difference: '',
            maxDifference: '',
            averageMiles: '',
            mileageMax: '',
            mileagePerYearMean: '',
            highestMileageName: '',
            highestMileageCount: '',
            highestAllCarMileageName: '',
            percentModel: 0,
            percentAllCar: 0,
            facetStatsCalculate: [],
            facetStatAvailable: false,
        },
    },
}).extend({
    creators: () => ({
        loadMileageData: () => async (dispatch, getState) => {
            const { query: { modelCode, listingType } } = getState();
            const params = {
                modelCode,
                listingType,
            };
            const [statsMileage, allCarStatsMileage] = await Promise.all([
                StatsMileageFetcher(params),
                StatsMileageFetcher({ ...params, modelCode: '' }),
            ]);

            const { max: mileageMax, mean: averageMiles, count: countMiles, facetSearchFieldStats } = statsMileage?.stats?.[0] || {};

            const mileagePerYear = statsMileage?.stats?.[1];
            const facetStats = statsMileage?.facetStats;
            const allCarCount = allCarStatsMileage?.stats?.[0]?.count;
            const allCarFacetStats = allCarStatsMileage?.facetStats;

            const { name: highestMileageName = null } = Array.isArray(facetStats) && facetStats.length !== 0 ? getHighestMileage(facetStats) : {};

            const { name: highestAllCarMileageName } = allCarFacetStats?.find((item) => (item.name === highestMileageName)) || {};

            /** Use mileage facetSearchFieldStats year to calculate the mean for name */
            const { minYear, difference, maxDifference } = calculateYearStats(facetSearchFieldStats?.year);
            const overMileageModelCount = getOverMileageCount(highestMileageName, facetStats);
            const facetStatsCalculate = facetStats
                ?.reduce((acc, { name }) => {
                    const countOver = getOverMileageCount(name, facetStats);
                    if (isTargetName(name) && countOver > 0) {
                        acc.push({
                            name: getNumberLocale(name),
                            percent: getPercent(countMiles, countOver),
                        });
                    }
                    return acc;
                }, []);

            const mileageData = {
                minYear,
                difference: getNumberLocale(difference),
                maxDifference: getNumberLocale(maxDifference),
                averageMiles: getNumberLocale(averageMiles),
                mileageOfMinYear: getNumberLocale(getMaxMileageByYear(String(minYear), facetSearchFieldStats)),
                mileageMax: getNumberLocale(mileageMax || 0),
                mileagePerYearMean: getNumberLocale(getMaxMileageByYear(getYearOfMaxMileage(mileageMax, facetSearchFieldStats), mileagePerYear?.facetSearchFieldStats)),
                highestMileageName: getNumberLocale(highestMileageName),
                highestMileageCount: getNumberLocale(overMileageModelCount),
                highestAllCarMileageName: getNumberLocale(highestAllCarMileageName),
                percentModel: getPercent(countMiles, overMileageModelCount),
                percentAllCar: getPercent(allCarCount, getOverMileageCount(highestMileageName, allCarFacetStats)),
                facetStatsCalculate,
                facetStatAvailable: facetStatsCalculate?.length !== 0,
                countTotalMileage: getOverMileageCount('50000', facetStats),
            };

            await dispatch(srpSEOMileageDuck?.creators?.setKeys({
                mileageData,
            }));
        },
    }),
    selectors: () => ({
        getMileageData: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            ({ mileageData }) => mileageData,
        )),
    }),
});
export default srpSEOMileageDuck;
